import React from 'react';
// import { Link } from 'gatsby';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectBanner from '../../components/projectBanner';

const PortfolioMondoz = () => {
  const data = useStaticQuery(graphql`
    fragment servicesImage on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      mondoz: file(relativePath: { eq: "mondoz.png" }) {
        ...servicesImage
      }

      mondozOrderApp: file(relativePath: { eq: "mondozOrderApp.png" }) {
        ...servicesImage
      }

      mondozOrderManagement: file(relativePath: { eq: "mondozOrderManagement.png" }) {
        ...servicesImage
      }

      mondozDeliveryPartnerApp: file(relativePath: { eq: "mondozDeliveryPartnerApp.png" }) {
        ...servicesImage
      }
    }
  `);

  return (
    <Layout pageInfo={{ pageName: 'mondoz' }} noLayoutbanner>
      <SEO title="Mondoz | Projects" />
      <ProjectBanner bgColor="#FF8002" title="Mondoz" description="On demand food delivery platform delivering authentic cuisines from local restaurants." image={data.mondoz.childImageSharp.fluid} />
      <TheProject />
      <OrderPlacement image={data.mondozOrderApp.childImageSharp.fluid} />
      <OrderFullfillment image={data.mondozOrderManagement.childImageSharp.fluid} />
      <OrderDelivery image={data.mondozDeliveryPartnerApp.childImageSharp.fluid} />
      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  );
};

export default PortfolioMondoz;

const TheProject = () => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 ">
        <Col md={4} xs={12}>
          <p className="text-left h4 mb-1 font-weight-bolder">The Project</p>
          <hr style={{ width: '100px' }} className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
        </Col>
        <Col md={8} xs={12}>
          <p style={{ width: '100%' }}>Mondoz is a startup based in Perth. We had to build an on demand food delivery app for connecting restaurants, diners and delivery partners seamlessly. We had to build a system comprising of applications for users to order food, restaurants to manage the orders and delivery partners to deliver food on time.</p>
        </Col>
      </Row>
    </Container>
  );
};

const OrderPlacement = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center">
        {/* <Row noGutters className="p-2 py-xl-5 pl-sm-0 mt-4"> */}
        <Col xs={12} className="text-center">
          <p className=" h4 mb-1 font-weight-bolder">Order Placement</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
        </Col>
        <Col xs={12} className="text-center d-flex justify-content-around">
          <p className="w-75 ">The face of Mondoz is the customer app where restaurants and the food they offer are listed. We built a fun intuitive app which allows hungry diners to find yummy food, quickly checkout and track their orders</p>
        </Col>
        {/* </Row> */}
        <Col xs={12}>
          <Img className="headshot w-100 mr-auto ml-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const OrderFullfillment = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center">
        <Col md={4} xs={12}>
          <p className="text-left h4 mb-1 font-weight-bolder">Order Management</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p className="w-100">Managing orders in a restaurant real challenge, we simplified it with an easy to use intuitive order management system that allows restaurant staff to view, manage and expedite orders with ease.</p>
        </Col>
        <Col md={8} xs={12}>
          <Img className="headshot w-100 mr-auto ml-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const OrderDelivery = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 flex-column-reverse flex-md-row">
        <Col md={8} xs={12}>
          <Img className="headshot w-100 mr-auto ml-auto d-block" fluid={image} alt="" />
        </Col>
        <Col md={4} xs={12}>
          <p className="text-left h4 mb-1 font-weight-bolder">Order Delivery</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p className="w-100">Delivery of food is crucial, and managing a fleet of delivery partners is not an easy job without a great system. The system we built assured proper distribution of orders among delivery partners and the mobile application delivery partners for problem free handling of orders in hand, keeping customer updated in real time.</p>
        </Col>
      </Row>
    </Container>
  );
};
